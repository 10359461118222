<template>
  <div id="popup" :class="'ol-popup'">
    <a
      href="#"
      id="popup-closer"
      class="ol-popup-closer"
      v-if="mapSetting !== 'gaodemap'"
      @click.prevent="closePopup"
    ></a>
    <div id="popup-content" v-if="currentData">
      <div class="text-content">
        <div class="item">
          <span>裸土编号：</span>
          <span>{{ currentData.gid }}</span>
        </div>
        <div class="item">
          <span>所属区县：</span>
          <span>{{ currentData.district }}</span>
        </div>
        <div class="item">
          <span>地址：</span>
          <span>{{ currentData.address }}</span>
        </div>
        <div class="item">
          <span>裸土状态：</span>
          <span>{{ currentData.status }}</span>
        </div>
        <div class="item">
          <span>类型：</span>
          <span>{{ currentData.type }}</span>
        </div>
        <div class="item">
          <span>图斑面积：</span>
          <span>{{ currentData.area ? Math.round(currentData.area) : "" }}(平方米)</span>
        </div>
        <div class="item" v-if="currentData.actualArea">
          <span>裸土面积：</span>
          <span>{{ currentData.actualArea }}(平方米)</span>
        </div>
        <div class="item" v-if="currentData.actualAreaRatio">
          <span>占比：</span>
          <span>{{ currentData.actualAreaRatio }}</span>
        </div>
        <div class="item">
          <span>最新扫描时间：</span>
          <span>{{ currentData.remoteSenseTime }}</span>
        </div>
        <div class="item" v-if="currentData && currentData.inspectionTime">
          <span>最后巡查时间：</span>
          <span>{{ currentData.inspectionTime }}</span>
        </div>
        <div class="item">
          <span>裸土经纬度：</span>
          <span>{{ currentData.lng }},{{ currentData.lat }}</span>
        </div>
      </div>
    </div>
    <div class="popup-content" v-else-if="thirdData">
      <div class="text-content">
        <div class="item" v-for="item in thirdData" :key="item.thirdColumn">
          <span>{{item['thirdColumnTitle']}}：</span>
          <span>{{ item['thirdColumnValue'] }}</span>
        </div>
        <!-- <div class="item">
          <span>裸土序号：</span>
          <span>{{ thirdData.thirdNo }}</span>
        </div>
        <div class="item">
          <span>所属区县：</span>
          <span>{{ thirdData.thirdDistrict }}</span>
        </div>
        <div class="item">
          <span>裸土面积：</span>
          <span>{{ thirdData.thirdArea }}(公顷)</span>
        </div>
        <div class="item">
          <span>裸土经纬度：</span>
          <span>{{ thirdData.thirdLng }},{{ thirdData.thirdLat }}</span>
        </div>
        <div class="item">
          <span>数据导入时间：</span>
          <span>{{ thirdData.createTime }}</span>
        </div>
        <div class="item">
          <span>来源文件：</span>
          <span>{{ thirdData.title }}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail, getThirdDetail } from '@/api'
import vueSeamlessScroll from 'vue-seamless-scroll'
import PatrolImages from './PatrolImages.vue'
import PatrolInspection from './PatrolInspection.vue'
export default {
  name: 'MapPopup',
  components: {
    vueSeamlessScroll,
    PatrolImages,
    PatrolInspection,
  },
  remoteLayerInfo: {
    deep: true,
    handler() {
      this.changeRemote()
    },
  },
  data() {
    return {
      show: false,
      currentData: null,
      classOption: {
        direction: 3,
      },
      picShow: false,
      patrolInspectionShow: false,
      fileList: [],
      uploadLoading: false,
      thirdData: null,
      thirdDataFlg: false,
    }
  },
  props: {
    mapSetting: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    previewImg(index) {
      this.$hevueImgPreview({
        nowImgIndex: index,
        imgList: this.currentData.imgList.map((item) => item.fileDownload),
        multiple: true,
        clickMaskCLose: true,
      })
    },
    showPop(currentId, month, year) {
      let params = { gid: currentId, month: month, year: year }
      return getDetail(params)
        .then((res) => {
          const { code, data } = res.data
          if (code === 200) {
            this.currentData = data
            this.show = true
          } else {
            this.currentData = null
          }
        })
        .catch(() => {
          this.currentData = null
        })
    },
    showThirdPop(thirdId, thirdTitle) {
      this.currentData = null
      let params = { thirdId: thirdId, title: thirdTitle }
      return getThirdDetail(params)
        .then((res) => {
          const { code, data } = res
          if (code === 200) {
            let thirdData = data
            thirdData = thirdData.filter(x => x.orderVal !== null)
            thirdData.sort((a, b) => a.orderVal - b.orderVal)
            this.show = true
            this.$nextTick(() => {
              this.thirdData = thirdData
            })
          } else {
            this.thirdData = null
          }
        })
        .catch(() => {
          this.thirdData = null
        })
    },
    showInfo() {
      this.picShow = true
    },
    addPatrolInspection() {
      this.patrolInspectionShow = true
    },
    closePatrolInspection() {
      this.patrolInspectionShow = false
    },
    closePopup() {
      this.$emit('closePopup')
    },
  },
}
</script>

<style scoped lang="less">
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 364px;
  z-index: 9;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: '✖';
}
.text-content {
  .item {
    padding: 6px 12px;
  }
}
.warp {
  width: 70px * 4;
  height: 80px;
  padding: 0 12px;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    &.ul-item {
      display: flex;
      .li-item {
        width: 8.2vh;
        height: 5vh;
        margin-right: 0.2vh;
        cursor: pointer;
        border: 2px solid #e0dada;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 8vh;
          height: 4.8vh;
        }
      }
    }
  }
}
.noimg {
  width: 70px * 4;
  height: 80px;
  padding: 0 12px;
  text-align: center;
  line-height: 80px;
}
.pic {
  display: flex;
}
</style>
