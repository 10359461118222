import Wkt from 'wicket'
export const gridsetName = 'EPSG:4326'

export const gridNames = [
  'EPSG:4326:0',
  'EPSG:4326:1',
  'EPSG:4326:2',
  'EPSG:4326:3',
  'EPSG:4326:4',
  'EPSG:4326:5',
  'EPSG:4326:6',
  'EPSG:4326:7',
  'EPSG:4326:8',
  'EPSG:4326:9',
  'EPSG:4326:10',
  'EPSG:4326:11',
  'EPSG:4326:12',
  'EPSG:4326:13',
  'EPSG:4326:14',
  'EPSG:4326:15',
  'EPSG:4326:16',
  'EPSG:4326:17',
  'EPSG:4326:18'
]

export const remoteBaseUrl = '//geoserver.novaecs.com/geoserver/qdyg/gwc/service/wmts'

export const remoteFormat = 'image/png'

export const districtOrder = [
  { title: '市南', order: 1 },
  { title: '市北', order: 2 },
  { title: '黄岛', order: 3 },
  { title: '西海岸', order: 3 },
  { title: '崂山', order: 4 },
  { title: '李沧', order: 5 },
  { title: '城阳', order: 6 },
  { title: '即墨', order: 7 },
  { title: '胶州', order: 8 },
  { title: '平度', order: 9 },
  { title: '莱西', order: 10 },
  { title: '高新', order: 11 },
]

// 裸土管理界面 区县列表
export const districts = [
  '市南区',
  '市北区',
  '黄岛区',
  '崂山区',
  '李沧区',
  '城阳区',
  '即墨区',
  '胶州市',
  '平度市',
  '莱西市',
  '高新区',
]

export const quarterOptions = [
  { title: '第一季度', value: 1 },
  { title: '第二季度', value: 2 },
  { title: '第三季度', value: 3 },
  { title: '第四季度', value: 4 },
]

// 裸土管理界面 区县列表
export const towns = [
  {district: '市南区', value:['香港中路街道', '八大峡街道','云南路街道','中山路街道','江苏路街道','八大关街道','湛山街道','金湖路街道','八大湖街道','金门路街道','珠海路街道']},
  {district: '市北区', value:['辽宁路街道','延安路街道','登州路街道','宁夏路街道','敦化路街道','辽源路街道','合肥路街道','大港街道','即墨路街道','台东街道','镇江路街道','浮山新区街道','阜新路街道','海伦路街道','四方街道','兴隆路街道','水清沟街道','洛阳路街道','河西街道','湖岛街道','开平路街道','双山街道 ']},
  {district: '黄岛区', value:['黄岛街道','辛安街道','薛家岛街道','灵珠山街道','长江路街道','红石崖街道','灵山卫街道','珠海街道','隐珠街道','铁山街道','滨海街道','胶南街道','王台街道','张家楼街道','琅琊镇','泊里镇','大场镇','大村镇','六汪镇','海青镇','宝山镇','藏马镇']},
  {district: '崂山区', value:['金家岭街道','中韩街道','沙子口街道','王哥庄街道','北宅街道']},
  {district: '李沧区', value:['振华路街道','沧口街道','兴华路街道','兴城路街道','李村街道','虎山路街道','浮山路街道','湘潭路街道','楼山街道','九水街道','世园街道']},
  {district: '城阳区', value:['城阳街道','夏庄街道','流亭街道','棘洪滩街道','上马街道','惜福镇街道','河套街道','红岛街道']},
  {district: '即墨区', value:['环秀街道','潮海街道','通济街道','北安街道','龙山街道','龙泉街道','鳌山卫街道','温泉街道','灵山街道','蓝村街道','大信街道','田横镇','金口镇','段泊岚镇','移风店镇']},
  {district: '胶州市', value:['阜安街道','中云街道','三里河街道','九龙街道','胶东街道','胶北街道','胶莱街道','李哥庄镇','铺集镇','里岔镇','胶西街道','洋河镇']},
  {district: '平度市', value:['东阁街道','李园街道','同和街道','凤台街道','白沙河街道','古岘镇','仁兆镇','南村镇','蓼兰镇','崔家集镇','明村镇','田庄镇','新河镇','店子镇','大泽山镇','旧店镇','云山镇']},
  {district: '莱西市', value:['水集街道','望城街道','沽河街道','姜山镇','夏格庄镇','院上镇','日庄镇','南墅镇','河头店镇','店埠镇','马连庄镇']},
  {district: '高新区', value:[]},
]

// 裸土管理界面 裸土状态
export const bareSoilStatus = [
  '新增',
  '扩增',
  '整改完成',
  '覆盖不完全',
  '留存',
  '其他',
  '办结'
]


// 级联过滤方法
export function filter (inputValue, path) {
  return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
}

// wkt multipolyline转为字符串
export function wktToStr (data) {
  let str = ''
  if (data && data.startsWith('MULTI')) {
    // wkt转为point
    const wkt = new Wkt.Wkt()
    const lines = wkt.read(data).components
    if (lines && lines.length) {
      for (let l = 0; l < lines.length; l++) {
        const points = lines[l]
        let lineStr = ''
        if (points && points.length) {
          for (let i = 0; i < points.length; i++) {
            if (i === points.length - 1) {
              lineStr += `${points[i].x},${points[i].y}`
            } else {
              lineStr += `${points[i].x},${points[i].y}\n`
            }
          }
        }
        if (l === lines.length - 1) {
          str += lineStr
        } else {
          str += lineStr + '\n\n'
        }
      }
    }
  }
  return str
}
// 字符串转wkt
export function strToWkt (data) {
  let str = ''
  if (data) {
    const coordinates = data.split('\n\n').map(line => {
      return line.split('\n').map(item => {
        return item.split(',')
      })
    })
    str = pointToWkt(coordinates)
  }
  return str
}

// 字符串转wkt
export function strToWkt2 (data) {
  let str = ''
  if (data) {
    const coordinates = data.split('|').map(line => {
        // return line.split(',')
        return line.split(';').map(item => {
        return item.split(',')
      })
    })
    str = pointToWkt(coordinates)
  }
  return str
}

// wkt转point
export function wktToPoint (data) {
  let points = []
  if (data && data !== 'null' && data.startsWith('POLYGON')) {
    const wkt = new Wkt.Wkt()
    points = wkt.read(data).components
  }
  return points
}

// wkt转为geojson
export function wktToGeo (data) {
  let points = []
  if (data && data !== 'null' && (data.startsWith('POLYGON') || data.startsWith('MULTI') || data.startsWith('Multi'))) {
    const wkt = new Wkt.Wkt()
    points = wkt.read(data).toJson()
  }
  return points
}

// geojson转为wkt
export function geoToWkt (geometry) {
  let str = ''
  if (geometry) {
    const wkt = new Wkt.Wkt()
    wkt.fromObject(geometry)
    str = wkt.write()
  }
  return str
}

// multiline的point转为wkt
export function pointToWkt (coordinates) {
  let str = ''
  if (coordinates && coordinates.length) {
    const wkt = new Wkt.Wkt()
    wkt.fromObject({ 'coordinates': coordinates, 'type': 'MultiLineString' })
    // wkt.fromObject({ 'coordinates': coordinates, 'type': 'MULTIPOLYGON' })

    str = wkt.write()
  }
  return str
}

// multipolygon转为字符串
export function multipolygonwktToStr (data) {
  let str = ''
  if (data && data.startsWith('MULTI')) {
    // wkt转为point
    const wkt = new Wkt.Wkt()
    const multipolygon = wkt.read(data).components
    if (multipolygon && multipolygon.length) {
      for (let l = 0; l < multipolygon.length; l++) {
        const polygons = multipolygon[l]
        let polygonsStr = ''
        if (polygons && polygons.length) {
          for (let i = 0; i < polygons.length; i++) {
            const polygon = polygons[i]
            let polygonStr = ''
            for (let j = 0; j < polygon.length; j++) {
              if (j === polygon.length - 1) {
                polygonStr += `${polygon[j].x},${polygon[j].y}`
              } else {
                polygonStr += `${polygon[j].x},${polygon[j].y}\n`
              }
            }
            if (i === polygons.length - 1) {
              polygonsStr += polygonStr
            } else {
              polygonsStr += `${polygonStr}\n\n`
            }
          }
        }
        if (l === multipolygon.length - 1) {
          str += polygonsStr
        } else {
          str += polygonsStr + '\n\n\n'
        }
      }
    }
  }
  return str
}

// 区域字符串转wkt
export function strTomultipolygonwkt (data) {
  let str = ''
  if (data) {
    const coordinates = data.split('\n\n\n').map(polygons => {
      return polygons.split('\n\n').map(polygon => {
        return polygon.split('\n').map(item => {
          return item.split(',')
        })
      })
    })
    if (coordinates && coordinates.length) {
      const wkt = new Wkt.Wkt()
      wkt.fromObject({ 'coordinates': coordinates, 'type': 'MultiPolygon' })
      str = wkt.write()
    }
  }
  return str
}

// 计算区域中心点坐标
export function getPloygonCenter (data) {
  let lng = 0
  let lat = 0
  let count = 0
  if (data) {
    const coordinates = data.split('\n\n\n').map(polygons => {
      return polygons.split('\n\n').map(polygon => {
        return polygon.split('\n').map(item => {
          return item.split(',')
        })
      })
    })
    if (coordinates.length) {
      for (let i = 0; i < coordinates.length; i++) {
        const polys = coordinates[i]
        if (polys.length) {
          for (let j = 0; j < polys.length; j++) {
            const poly = polys[j]
            if (poly.length) {
              for (let p = 0; p < poly.length; p++) {
                lng += Number(poly[p][0])
                lat += Number(poly[p][1])
                count = count + 1
              }
            }
          }
        }
      }
      lng = lng / count
      lat = lat / count
    }
  }
  return { lng, lat }
}
export function getSinglePolygonCenter (points) {
  let x = 0.0
  let y = 0.0
  if (points && points.length) {
    for (const point of points) {
      x = x + parseFloat(point.lng)
      y = y + parseFloat(point.lat)
    }
    x = x / points.length
    y = y / points.length
  }
  const point = new BMap.Point(x, y)
  return point
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
 export function difference (object, base) {
  function changes (object, base) {
      return _.transform(object, function (result, value, key) {
          if (!_.isEqual(value, base[key])) {
              result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value
          }
      })
  }
  return changes(object, base)
}

// 遍历树形数据
export function traverseTreeData (data) {
  if (data.length) {
    for (let i = 0; i < data.length; i++) {
      data[i].scopedSlots = { title: 'name' }
      // 已经绑定（存在packageId和regionId）的区域禁止点击
      const { packageId, regionId } = data[i]
      if (packageId && regionId) {
        data[i].disabled = true
      }
      if (data[i].children.length) {
        traverseTreeData(data[i].children)
      }
    }
  }
}

// 计算字符串长度
/**
 * 获取输入内容的长度
 * @param value
 * @return 内容的长度
 */
export const getInputSize = (value) => {
  if (!value) {
    return 0
  }
  const charCount = value.split('').reduce((prev, curr) => {
    // 英文字母和数字等算一个字符；这个暂时还不完善；
    if (/[a-z]|[0-9]|[,;.!@#-+/\\$%^*()<>?:"'{}~]/i.test(curr)) {
      return prev + 1
    }
    // 其他的算是2个字符
    return prev + 2
  }, 0)

  // 向上取整，防止出现半个字的情况
  return Math.ceil(charCount / 2)
}

export function transformDistanceToPixel (map, distance) {
  if (!map) { return }
   const origin = map.getCenter()
   // 中心点坐标
   const point = new BMap.Point(origin.lng, origin.lat)
   const center = map.pointToPixel(point)
   // 半径
   const turfPoint = turf.point([origin.lng, origin.lat])
   const bearing = 90
   const options = { units: 'kilometers' }
   const destinationPoint = turf.destination(
     turfPoint,
     distance / 1000,
     bearing,
     options
   )
   const coordinates = destinationPoint.geometry.coordinates
   const destination = new BMap.Point(coordinates[0], coordinates[1])
   const dCenter = map.pointToPixel(destination)
   const pixel = Math.abs(center.x - dCenter.x)
   return pixel
}

class Enum {
  #valKeyMap = {}

  constructor(initObj) {
    Object.entries(initObj).forEach(([key, value]) => {
      this[key] = value
    })
    this.#valKeyMap = Object.entries(initObj).reduce((acc, [key, value]) => {
      acc[value] = key
      return acc
    }, {})
  }

  keyByVal(v) {
    return this.#valKeyMap[v]
  }
}

export const YGCRSTypes = new Enum({
  WGS84: 'EPSG:4326',
  BD09: 'BD:09',
})
