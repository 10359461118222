const configBoard = {
  header: ['裸土编号', '裸土类型', '裸土面积(平方米)'],
  data: [],
  index: false,
  rowNum: 10,
  headerHeight: 50,
  columnWidth: [140, 160, 200],
  align: ['center'],
  carousel: 'single'
}

const configChart = {
  radius: '80%',
  activeRadius: '90%',
  data: [],
  digitalFlopStyle: {
    fontSize: 20
  }
}

const colorDict = {
  施工工地: '#108ee9',
  裸露土地: '#FF7F00',
  其他: '#87d068',
  物料堆场: '#7c11e0',
  农田: '#2db7f5'
}

const columns = [
  {
    title: '裸土编号',
    dataIndex: 'gid',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    },
    onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    }
  },
  {
    title: '裸土类型',
    dataIndex: 'type'
  },
  {
    title: '裸土面积(平方米)',
    dataIndex: 'area',
    type: 'number'
  }
]

const stationColumns = [
  {
    label: '标站名称',
    field: 'station'
  },
  {
    label: 'PM10',
    field: 'pm10',
    default: '--'
  },
  {
    label: '绘制开关',
    field: 'switch'
  }
]

const markScale = {
  13: 1,
  12: 0.8,
  11: 0.6,
  10: 0.5,
  9: 0.4,
  8: 0.3,
  7: 0.2,
  6: 0.1
}

// 图标配合
const pieOption = {
  title: {
    x: '42%', // X坐标
    y: '40%', // Y坐标
    text: '总数', // 主标题
    subtext: 0, // 副标题
    left: 'center',
    textStyle: {
      // 标题样式
      width: 40,
      fontSize: 16,
      fontWeight: 'bolder',
      color: '#aaa',
      transform: 'translate(-50%,-50%)',
      marginTop: '-50%',
      marginLeft: '-50%'
    },
    subtextStyle: {
      // 副标题样式
      fontSize: 16,
      fontWeight: 'bolder',
      color: '#fefefe',
      formatter: '',
      marginTop: '-50%',
      marginLeft: '-50%',
      transfrom: 'translate(-50%,-50%)'
    }
  },
  tooltip: {
    show: true,
    trigger: 'item'
  },
  legend: {
    bottom: '1%',
    left: 'center',
    textStyle: {
      color: '#fff'
    },
    selectedMode: false
  },
  series: [
    {
      name: '',
      type: 'pie',
      radius: ['30%', '60%'],
      avoidLabelOverlap: false,
      label: {
        show: true,
        width: 80,
        position: 'outer',
        overflow: 'break',
        formatter (param) {
          return `${param.name}(${param.value})`
        },
        color: '#fff',
        fontSize: 16
      },
      labelLine: {
        show: true
      },
      data: []
    }
  ]
}

const bareSoilColumns = [
  {
    title: '编号',
    dataIndex: 'gid',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender'
    },
    onFilter: (value, record) =>
      record.gid.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => {
      //     this.searchInput.focus();
      //   }, 0);
      // }
    },
    width: '70px'
  },
  {
    title: '所属街道',
    dataIndex: 'town'
  },

  {
    title: '裸土状态',
    dataIndex: 'status'
  }
]

export { configBoard, configChart, colorDict, columns, markScale, pieOption, stationColumns, bareSoilColumns }
