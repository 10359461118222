<template>
  <div id="map"></div>
</template>

<script>
import { center, point, projection } from '@turf/turf';
import { colorDict, markScale } from '../options'
import { gridsetName, gridNames, remoteBaseUrl, remoteFormat, wktToGeo,strToWkt2 } from '../utils/utils'
import gcoord from 'gcoord'
import gcj02Mecator from '../utils/gcj02Mecator'
export default {
  name: 'MapView',
  data() {
    return {
      map: null,
      satationDrawRange: this.satationDrawRangeVal,
      remoteLayer: null,
      tiandiMapLayer: null,
      tiandiTextLayer: null,
      mapboxMapLayer: null,
      baiduMapLayer: null,
      vectorLayer2: null,
      multiPolygonVectorLayer: null,
      draw: null,
      vectorSource: null,
      addSource: new ol.source.Vector(),
      vectorLayer: null,
      thirdLayer:null,
      baiduWxMapLayer:null,
      projection:'BD09',
      mapLayers:[],
      bd09view:null,
      wgs84view:null,
      gcj02view:null,
      tiandiWxMapLayer:null,
      tiandiWxTextLayer:null,
      gaodeWxMapLayer:null,
      gaodeMapLayer:null,
      gaodeWxTextMapLayer:null,
      baiduWxTextMapLayer:null,
      mapColorFunction:null,
      uesrName:sessionStorage.getItem("userName"),
    }
  },
  props: {
    satationDrawRangeVal: {
      type: Number,
      default: 5000, //默认值
    },
    remoteLayerInfo: {
      required: true,
    },
    mapType:{
      require: true,
    }
  },
  mounted() {
    // 增加坐标系
    this.createCoordinate()
    // 初始化地图
    this.initMap('BD09')
  },
  watch: {
    remoteLayerInfo: {
      deep: true,
      handler() {
        this.changeRemote()
      },
    },
  },
  methods: {
    initMap(CRS) {
      // 初始化图层
      this.createMapLayer()
      // 初始化VIEW
      this.createMapView()
      // 初始化地图
      this.createMap()
      // 初始化地图中心
      this.moveMap(CRS)
      this.map.getView().on('change:resolution', () => {
        this.addFeatures()
      })
      this.$nextTick(function () {
        this.$emit('getMap', this.map)
      })
      // 增加经纬度的显示
      var mousePositionControl = new ol.control.MousePosition({
            coordinateFormat: ol.coordinate.createStringXY(4), //设置坐标值的格式四位小数
            projection: 'EPSG:4326',
            target: 'mouse-position',
            className: 'custom-mouse-position',
            undefinedHTML: '&nbsp'
        })
        this.map.addControl(mousePositionControl)
    },

    addFeatures(areasData) {
      if (areasData) {
        this.areasData = areasData
      }
      this.removeFeatures()
      // 获取缩放级别
      const zoom = this.map.getView().getZoom()

      if (zoom < 14 && zoom > 6) {
        this.markFeature = new ol.layer.Vector({
          source: new ol.source.Vector(),
        })

        // 构建svg的Image对象
        var svg =
          '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">' +
          '<path fill="#156BB1" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>' +
          '<circle fill="#FFFFFF" cx="15" cy="10.677" r="3.291"/></svg>'

        var mysvg = new Image()
        mysvg.src = 'data:image/svg+xml,' + escape(svg)

        const anchorStyle = new ol.style.Style({
          image: new ol.style.Icon({
            img: mysvg, // 设置Image对象
            imgSize: [30, 30], // 及图标大小
          }),
        })

        anchorStyle.getImage().setScale(markScale[zoom] || 0.1)

        if (this.areasData?.length) {
          for (let item of this.areasData) {
            if (item.zstFlag) {
              const anchor = new ol.Feature({
                geometry: new ol.geom.Point([item.lng, item.lat]),
              })
              anchor.setStyle(anchorStyle)
              this.markFeature.getSource().addFeature(anchor)
            }
          }
        }
        this.map.addLayer(this.markFeature)
      }
    },

    removeFeatures() {
      this.markFeature && this.map.removeLayer(this.markFeature)
      this.markFeature = null
    },

    drawPolygons(areasData,) {
      this.removePolygons()
      let pro = this.map.getView().getProjection()
      if (areasData?.length) {
        let features = []
        for (let item of areasData) {
          let coordinates = []
          if (item.geomJson) {
            coordinates = JSON.parse(item.geomJson).coordinates
          }

          let polygon = new ol.geom.MultiPolygon(coordinates).transform('EPSG:4326',pro)

          const feature = new ol.Feature(polygon)
          item.featureType = 'areas'
          feature.setProperties(item)
          features.push(feature)
        }
        this.vectorSource = new ol.source.Vector()
        this.vectorSource.addFeatures(features)
        this.vectorLayer = new ol.layer.Vector({
          source: this.vectorSource,
          style: function (feature) {
            let name = feature.getProperties().type
            if (!name) {
              name = '裸漏土地'
            }
            console.log(name)

            const color = name ? colorDict[name] : '#FF7F00'

            const style = new ol.style.Style({
              //填充色
              fill: new ol.style.Fill({
                color: 'rgba(255, 255, 255, 0.15)',
              }),
              //边线颜色
              stroke: new ol.style.Stroke({
                color: color,
                width: 2,
              }),
              //形状
              image: new ol.style.Circle({
                radius: 7,
                fill: new ol.style.Fill({
                  color: '#ffcc33',
                }),
              }),
            })
            return [style]
          },
          zIndex: 9,
        })

        this.map.addLayer(this.vectorLayer)
      }
    },
    // 清除多边形区域（裸土区域）
    removePolygons() {
      this.vectorLayer && this.map.removeLayer(this.vectorLayer)
    },
    // 绘制标站
    drawStation(station, el) {
      let pro = this.map.getView().getProjection()
      const pos = ol.proj.transform([station.lng,station.lat], 'EPSG:4326', pro)
      var marker = new ol.Overlay({
        id: station.sn,
        position: pos,
        // positioning: "center-center",
        element: el,
        stopEvent: false,
      })
      this.map.addOverlay(marker)
    },
    // 删除所有标站
    removeStation(reStations) {
      for (const iterator of reStations) {
        let _overlay = this.map.getOverlayById(iterator.sn)
        this.map.removeOverlay(_overlay)
      }
    },
    // 绘制标站方圆区域
    drawStationCircle(stations, drowRange) {
      let pro = this.map.getView().getProjection()
      let sourcePoint = new ol.source.Vector() // 创建数据源
      let layerPoint = new ol.layer.Vector({
        // 创建图层
        zIndex: 18, // 图层的层级
      })
      layerPoint.setSource(sourcePoint) // 把数据源绑定到图层上面
      this.map.addLayer(layerPoint) // 把图层添加到地图上面去
      let features = []
      for (const station of stations) {
        const pos = ol.proj.fromLonLat([station.lng, station.lat], 'EPSG:4326')
        let feature = this.getRadius(pos, drowRange,pro);
        feature.setStyle(
          new ol.style.Style({
            fill: new ol.style.Fill({
              color: 'rgba(32, 157, 230, 0.3)',
            }),
          })
        )
        feature.setProperties({
          // 设置其他的数据
          name: 'stationCircle',
          sn: station.sn,
          type: 'station',
        })
        feature.setId(station.sn)
        features.push(feature)
      }
      sourcePoint.addFeatures(features)
    },
    // 删除标站方圆区域
    deleteStationCircle(stations) {
      for (let index = 0; index < this.map.getLayers().getProperties().length; index++) {
        let layers = this.map.getLayers().getArray()
        for (let i in layers) {
          let source = layers[i].getSource()
          if (source instanceof ol.source.Vector) {
            for (const station of stations) {
              let fea = source.getFeatureById(station.sn)
              if (fea) {
                source.removeFeature(fea)
              }
            }
          }
        }
      }
    },
    drawRegionBoundary(regionBoundaryDatas) {
      if (regionBoundaryDatas?.length) {
        let features = []
        for (let item of regionBoundaryDatas) {
          let feature = this.getFeatureByWKT(item.data)
          feature.setProperties({ type: 'regionBoundary', name: item.name })
          features.push(feature)
        }
        const vectorSource = new ol.source.Vector()
        vectorSource.addFeatures(features)
        if (!this.vectorLayer2) {
          this.vectorLayer2 = new ol.layer.Vector({
            source: vectorSource,
            style: function () {
              const color = '#ffffff'
              const style = new ol.style.Style({
                // //填充色
                // fill: new ol.style.Fill({
                //   color: "rgba(255, 255, 255, 0.15)",
                // }),
                //边线颜色
                stroke: new ol.style.Stroke({
                  color: color,
                  width: 2,
                }),
                //形状
                image: new ol.style.Circle({
                  radius: 7,
                  fill: new ol.style.Fill({
                    color: '#ffcc33',
                  }),
                }),
              })
              return [style]
            },
            zIndex: 8,
          })
          this.map.addLayer(this.vectorLayer2)
        } else {
          this.vectorLayer2.setSource(vectorSource)
        }
      }
    },
    drawBdRegionBoundary(regionBoundaryDatas) {
      const vectorSource = new ol.source.Vector()
      let pro = this.map.getView().getProjection()
      let features = []

      for (let index = 0; index < regionBoundaryDatas.length; index++) {
        const {data} = regionBoundaryDatas[index]
         if (data.type === 'bdRegion') {
          let bdFeature = this.getFeatureByWKT(data.districts[0].polyline, 'bd09')
          bdFeature.setProperties({ type: 'regionBoundary', name: 'bd' })
          bdFeature.getGeometry().transform('EPSG:4326',pro)
          features.push(bdFeature)
        } else if (data.type === 'gdRegion') {
          let gdFeature = this.getFeatureByWKT(data.districts[0].polyline, 'gcj02')
          gdFeature.setProperties({ type: 'regionBoundary', name: 'gd' })
          gdFeature.getGeometry().transform('EPSG:4326',pro)
          features.push(gdFeature)
        }else {
          for (let item of data) {
            let feature = this.getFeatureByWKT(item.data)
            feature.setProperties({ type: 'regionBoundary', name: item.name })
            feature.getGeometry().transform('EPSG:4326',pro)
            features.push(feature)
          }
        }
      }

      vectorSource.addFeatures(features);

      if (!this.vectorLayer2) {
        this.vectorLayer2 = new ol.layer.Vector({
          source: vectorSource,
          style: function (feature) {
            const name = feature.getProperties().name
            let color = '#4dff4d'
            if (name === 'bd') {
              color = '#0066ff'
            }else if (name ==='gd'){
              color = '#cc00ff'
            }
            // color = name === 'bd' ? '#ff0000' : '#ffffff'
            const style = new ol.style.Style({
              //边线颜色
              stroke: new ol.style.Stroke({
                color: color,
                width: 2,
              }),
              //形状
              image: new ol.style.Circle({
                radius: 7,
                fill: new ol.style.Fill({
                  color: '#ffcc33',
                }),
              }),
            })
            return [style]
          },
          zIndex: 8,
        })
        this.map.addLayer(this.vectorLayer2)
      } else {
        this.vectorLayer2.setSource(vectorSource)
      }
    },
    deleteRegionBoundary() {
      if (this.vectorLayer2) {
        this.vectorLayer2.getSource().clear()
        this.map.removeLayer(this.vectorLayer2)
        this.vectorLayer2 = null
      }
    },
    drawMultiPolygon(multiPolygonDatas) {
      const vectorSource = new ol.source.Vector()
      for (let index = 0; index < multiPolygonDatas.length; index++) {
        const data = multiPolygonDatas[index]
        let features = []
        let feature = this.getFeatureByWKT(data.thirdGeom)
        feature.setProperties({ type: 'MultiPolygon', thirdTitle: data.thirdTitle})
        features.push(feature)
        vectorSource.addFeatures(features)
      }

      if (!this.multiPolygonVectorLayer) {
        this.multiPolygonVectorLayer = new ol.layer.Vector({
          source: vectorSource,
          style: function (feature) {
            let color = '#00ffff'
            const style = new ol.style.Style({
              //边线颜色
              stroke: new ol.style.Stroke({
                color: color,
                width: 2,
              }),
              //形状
              image: new ol.style.Circle({
                radius: 7,
                fill: new ol.style.Fill({
                  color: '#ffcc33',
                }),
              }),
            })
            return [style]
          },
          zIndex: 8,
        })
        this.map.addLayer(this.multiPolygonVectorLayer)
      } else {
        this.multiPolygonVectorLayer.setSource(vectorSource)
      }
    },
    deleteMultiPolygon() {
      if (this.multiPolygonVectorLayer) {
        this.multiPolygonVectorLayer.getSource().clear()
        this.map.removeLayer(this.multiPolygonVectorLayer)
        this.multiPolygonVectorLayer = null
      }
    },
    /**
     * @todo wkt格式数据转化成图形对象
     * @param {string} wkt   "POINT(112.7197265625,39.18164062499999)" 格式数据
     * @param {string|Projection} sourceCode 源投影坐标系
     * @param {string|Projection} targetCode 目标投影坐标系
     * @returns {Feature}
     */
    getFeatureByWKT(wkt, coordinate, sourceCode, targetCode) {
      if (!coordinate) {
        try {
          let view = this.map.getView()
          if (!wkt) {
            return null
          }
          // 数据格式类型
          let format = new ol.format.WKT()

          let feature
          feature = format.readFeature(wkt, {
            featureProjection: targetCode || view.getProjection(),
            dataProjection: sourceCode || view.getProjection(),
          })

          return feature
        } catch (e) {
          console.log(e)
          return null
        }
      } else if(coordinate === 'bd09') {
        let view = this.map.getView()
        let geo = wktToGeo(wkt)
        var result = gcoord.transform(
          geo, // 经纬度坐标
          gcoord.BD09, // 当前坐标系
          gcoord.WGS84 // 目标坐标系
        )

        // 数据格式类型
        let format = new ol.format.GeoJSON()
        let feature
          feature = format.readFeature(result, {
            featureProjection: targetCode || view.getProjection(),
            dataProjection: sourceCode || view.getProjection(),
          })
          return feature
      } else if(coordinate === 'gcj02') {
        let view = this.map.getView()
        let wkt2 = strToWkt2(wkt)

        let geo = wktToGeo(wkt2)
        // geo.type = "Polygon"
        // geo.coordinates = [geo.coordinates]

        var result = gcoord.transform(
          geo, // 经纬度坐标
          gcoord.GCJ02, // 当前坐标系
          gcoord.WGS84 // 目标坐标系
        )


        // 数据格式类型

        let format = new ol.format.GeoJSON()
        let feature
          feature = format.readFeature(result, {
            featureProjection: targetCode || view.getProjection(),
            dataProjection: sourceCode || view.getProjection(),
          })
          return feature
      }
    },
        /**
     * @todo wkt格式数据转化成图形对象
     * @param {string} wkt   "POINT(112.7197265625,39.18164062499999)" 格式数据
     * @param {string|Projection} sourceCode 源投影坐标系
     * @param {string|Projection} targetCode 目标投影坐标系
     * @returns {Feature}
     */
    getFeatureByWKT(wkt, coordinate, sourceCode, targetCode) {
      if (!coordinate) {
        try {
          let view = this.map.getView()
          if (!wkt) {
            return null
          }
          // 数据格式类型
          let format = new ol.format.WKT()

          let feature
          feature = format.readFeature(wkt, {
            featureProjection: targetCode || view.getProjection(),
            dataProjection: sourceCode || view.getProjection(),
          })

          return feature
        } catch (e) {
          console.log(e)
          return null
        }
      } else if(coordinate === 'bd09') {
        let view = this.map.getView()
        let geo = wktToGeo(wkt)
        var result = gcoord.transform(
          geo, // 经纬度坐标
          gcoord.BD09, // 当前坐标系
          gcoord.WGS84 // 目标坐标系
        )

        // 数据格式类型
        let format = new ol.format.GeoJSON()
        let feature
          feature = format.readFeature(result, {
            featureProjection: targetCode || view.getProjection(),
            dataProjection: sourceCode || view.getProjection(),
          })
          return feature
      } else if(coordinate === 'gcj02') {
        let view = this.map.getView()
        let wkt2 = strToWkt2(wkt)

        let geo = wktToGeo(wkt2)
        // geo.type = "Polygon"
        // geo.coordinates = [geo.coordinates]

        var result = gcoord.transform(
          geo, // 经纬度坐标
          gcoord.GCJ02, // 当前坐标系
          gcoord.WGS84 // 目标坐标系
        )


        // 数据格式类型

        let format = new ol.format.GeoJSON()
        let feature
          feature = format.readFeature(result, {
            featureProjection: targetCode || view.getProjection(),
            dataProjection: sourceCode || view.getProjection(),
          })
          return feature
      }
    },
    constructSource() {
      const style = ''
        const projection = new ol.proj.Projection({
          code: 'EPSG:4326',
          units: 'degrees',
          axisOrientation: 'neu',
        })
        const resolutions = [
          0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125, 0.02197265625, 0.010986328125, 0.0054931640625,
          0.00274658203125, 0.001373291015625, 6.866455078125e-4, 3.4332275390625e-4, 1.71661376953125e-4,
          8.58306884765625e-5, 4.291534423828125e-5, 2.1457672119140625e-5, 1.0728836059570312e-5, 5.364418029785156e-6,
          2.682209014892578e-6, 1.341104507446289e-6, 6.705522537231445e-7, 3.3527612686157227e-7,
        ]
        const baseParams = ['VERSION', 'LAYER', 'STYLE', 'TILEMATRIX', 'TILEMATRIXSET', 'SERVICE', 'FORMAT']

        const params = {
          VERSION: '1.0.0',
          LAYER: this.remoteLayerInfo.layerName,
          STYLE: style,
          TILEMATRIX: gridNames,
          TILEMATRIXSET: gridsetName,
          SERVICE: 'WMTS',
          FORMAT: remoteFormat,
        }

        var url = remoteBaseUrl + '?'

        for (var param in params) {
          if (baseParams.indexOf(param.toUpperCase()) < 0) {
            url = url + param + '=' + params[param] + '&'
          }
        }
        url = url.slice(0, -1)

        let extend = [
          this.remoteLayerInfo.minX,
          this.remoteLayerInfo.minY,
          this.remoteLayerInfo.maxX,
          this.remoteLayerInfo.maxY,
        ]

        const source = new ol.source.WMTS({
          url: url,
          layer: params['LAYER'],
          matrixSet: params['TILEMATRIXSET'],
          format: params['FORMAT'],
          projection: projection,
          tileGrid: new ol.tilegrid.WMTS({
            tileSize: [256, 256],
            extent: extend,
            origin: [-180.0, 90.0],
            resolutions: resolutions,
            matrixIds: params['TILEMATRIX'],
          }),
          style: params['STYLE'],
          wrapX: true,
        })
        return source
    },
    changeRemote() {
      this.remoteLayer.setSource(this.constructSource())
      this.moveMap()
    },
    clearRemote() {
      this.remoteLayer.setSource(null)
    },
    moveMap(reExtend,oldPro,oldSize) {
      let pro =  this.map.getView().getProjection()
      let extent
      if(reExtend !=undefined && oldPro !=undefined){
        reExtend = ol.extent.applyTransform(reExtend,ol.proj.getTransform(oldPro,'EPSG:4326'))
        extent = ol.extent.applyTransform(reExtend,ol.proj.getTransform('EPSG:4326',pro))
      }else{
        extent = ol.extent.applyTransform(this.remoteLayer.getSource().getTileGrid().getExtent(),ol.proj.getTransform('EPSG:4326',pro))
    }
      // 设定extend
      // 移动地图位置
      this.map.getView().fit(extent,{size: oldSize || this.map.getSize(), nearest: true} )
    },
    /**
     *
     * @param mapSetting 地图商家
     * @param mapType 街道地图/卫星地图
     * @param showBaseMap 是否显示地图底图
     * @param showBaseMapText 是否显示路网信息
     * @param mapColor
     */
    mapChange(mapSetting, mapType, showBaseMap, showBaseMapText,mapColor) {
      // 清空layer
      this.mapLayersVisible();
      // 设定中心经纬度和zoom
      // 获取当前视图
      var view = this.map.getView();

      // 获取中心点的坐标
      var center = view.getCenter();

      // 打印中心点的经纬度
      console.log(center[0] + ", " + center[1]);


      // 设置地图视图
      const viewMap = {
        'baidumap': this.bd09view,
        'tiandimap': this.wgs84view,
        'gaodemap': this.gcj02view
      };
      this.map.setView(viewMap[mapSetting] || this.bd09view); // 假设存在一个默认视图

      // 定义地图层可见性设置函数
      const setLayerVisibility = (layer, visible) => {
        if (layer) {
          layer.setVisible(visible);
        }
      };

      // 定义文本层可见性设置函数
      const setTextLayerVisibility = (textLayer, visible) => {
        if (textLayer) {
          textLayer.setVisible(visible);
        }
      };

      // 根据mapSetting获取对应的地图层和文本层
      const layersConfig = {
        'baidumap': {
      baseLayer: this.baiduMapLayer,
      wxLayer: this.baiduWxMapLayer,
      textLayer: null,
      wxTextLayer: this.baiduWxTextMapLayer // 如果baiduWxTextMapLayer和baiduWxTextMapLayer不是同一个，请分开
    },
    'tiandimap': {
      baseLayer: this.tiandiMapLayer,
      wxLayer: this.tiandiWxMapLayer,
      textLayer: this.tiandiTextLayer,
      wxTextLayer: this.tiandiWxTextLayer
    },
    'gaodemap': {
      baseLayer: this.gaodeMapLayer,
      wxLayer: this.gaodeWxMapLayer,
      textLayer: null,
      wxTextLayer: this.gaodeWxTextMapLayer
    }
      };

      const { baseLayer, wxLayer, textLayer, wxTextLayer } = layersConfig[mapSetting] || {};

      // 根据mapType和显示设置调整层可见性
      if (mapType === 'jiedaoditu') {
        setLayerVisibility(baseLayer, showBaseMap);
        setLayerVisibility(wxLayer, false);
        setTextLayerVisibility(textLayer, showBaseMapText);
        setTextLayerVisibility(wxTextLayer, showBaseMapText && textLayer !== wxTextLayer); // 如果textLayer和wxTextLayer不同，且需要显示文本层
      } else if (mapType === 'weixingditu') {
        setLayerVisibility(baseLayer, false);
        setLayerVisibility(wxLayer, showBaseMap);
        setTextLayerVisibility(textLayer, false);
        setTextLayerVisibility(wxTextLayer, showBaseMapText);
      }

      // 调整地图颜色
      if (mapColor === 'dark') {
        this.map.getLayers().getArray().forEach(layer => {
          if(layer.getVisible() && layer.getProperties().canDark){
            if(layer.getSource().getTileLoadFunction() !== this.darkfunction){
              layer.getSource().setTileLoadFunction(this.darkfunction)
              // layer.getSource().clear()
              // layer.getSource().refresh()
              // this.map.render()
            }
          }
        })
      } else if (mapColor === 'white') {
        this.map.getLayers().getArray().forEach(layer => {
          if(layer.getVisible() && layer.getProperties().canDark){
            if(layer.getSource().getTileLoadFunction() !== this.whitefunction){
              layer.getSource().setTileLoadFunction(this.whitefunction)
              // layer.getSource().clear()
              // layer.getSource().refresh()
              // this.map.render()
            }
          }
        })
      }
    },
    darkfunction(imageTile, src){
      // 使用滤镜 将白色修改为深色
          const img = new Image();
          // img.crossOrigin = ''
          // 设置图片不从缓存取，从缓存取可能会出现跨域，导致加载失败
          img.setAttribute("crossOrigin", "anonymous");
          img.onload = function () {
            const canvas = document.createElement("canvas");
            const w = img.width;
            const h = img.height;
            canvas.width = w;
            canvas.height = h;
            const context = canvas.getContext("2d");
            context.filter = "grayscale(98%) invert(100%) sepia(20%) hue-rotate(180deg) saturate(1600%) brightness(80%) contrast(90%)";
            context.drawImage(img, 0, 0, w, h, 0, 0, w, h);
            imageTile.getImage().src = canvas.toDataURL("image/png");
          };
          img.src = src;
    },
    whitefunction(imageTile, src){
      imageTile.getImage().src = src;
    },
    changeRemoteLaVisible(val) {
      this.remoteLayer.setVisible(val)
    },
    changeBareSoilLaVisible(val) {
      if (val) {
        this.vectorLayer2.style = null
      } else {
        // this.vectorLayer2.style = new ol.style.Style({
        //   // //填充色
        //   // fill: new ol.style.Fill({
        //   //   color: "rgba(255, 255, 255, 0.15)",
        //   // }),
        //   //边线颜色
        //   stroke: new ol.style.Stroke({
        //     color: color,
        //     width: 2,
        //   }),
        //   //形状
        //   image: new ol.style.Circle({
        //     radius: 7,
        //     fill: new ol.style.Fill({
        //       color: "#ffcc33",
        //     }),
        //   }),
        // });
      }
    },
    addInteraction(type) {
      console.log('addInteraction', this.vectorSource)
      // 添加交互绘制控件
      this.draw = new ol.interaction.Draw({
        source: this.vectorSource,
        type: type,
      })
      this.draw.on('drawend', function () {
        console.log('draw', this.vectorSource)
      })
      this.map.addInteraction(this.draw)
    },

    drawPolygon() {
      // 绘制面
      this.addInteraction('Polygon')
    },

    clearDraw() {
      // 清空绘制
      this.addSource.clear()
    },
    exitDraw() {
      // 退出绘制模式
      if (this.draw) {
        this.map.removeInteraction(this.draw)
        // this = null;
      }
    },
    getRadius(pos, drowRange,pro) {
      var options = { units: "meters" };
      var circle = this.$turf.circle(pos, drowRange, options);
      let format = new ol.format.GeoJSON()
      let feature = format.readFeature(circle, {
        featureProjection: pro,
        dataProjection: 'EPSG:4326',
      })
      return feature
    },
    drawPoints(points) {
      let pro = this.map.getView().getProjection()
        var svgBlue =
          '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">' +
          '<path fill="#156BB1" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>' +
          '<circle fill="#FFFFFF" cx="15" cy="10.677" r="3.291"/></svg>'
        var svgRed =
          '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">' +
          '<path fill="#FF0001" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>' +
          '<circle fill="#FFFFFF" cx="15" cy="10.677" r="3.291"/></svg>'
      let features = [];
      let svg = '';
      points.forEach(point => {
        let feature = new ol.Feature({
          geometry: new ol.geom.Point(ol.proj.transform([point.thirdLng, point.thirdLat], 'EPSG:4326',pro))
        });

        if(point.correlation){
          svg = 'data:image/svg+xml,' + escape(svgBlue)
        }else{
          svg = 'data:image/svg+xml,' + escape(svgRed)
        }
        feature.setStyle(
          new ol.style.Style({
            image: new ol.style.Icon({
              anchor: [0.5, 1], //锚点
              opacity: 1,
              scale: 1,
              src: svg //图标的URL
            })
          })
        );
        feature.setProperties({valueType:'thirdPoint',thirdId: point.id, thirdTitle: point.thirdTitle})
        feature.setProperties(point)
        features.push(feature);
      });

        let source = new ol.source.Vector()
        source.addFeatures(features)
        if (!this.thirdLayer) {
        this.thirdLayer = new ol.layer.Vector({
          zIndex: 10,
          opacity: 1
        })
      }
      this.thirdLayer.setSource(source)
      this.map.addLayer(this.thirdLayer)
      },
      removePoints(){
        this.map.removeLayer(this.thirdLayer)
      },
    /**
     * 增加坐标系（百度、高德）
     */
    createCoordinate() {
      // 增加百度地图坐标系
      let projBD09 = new ol.proj.Projection({
        code: 'BD09',
        extent: [-20037726.37, -11708041.66, 20037726.37, 12474104.17],
        units: 'm',
        axisOrientation: 'neu',
        global: false
      });

      ol.proj.addProjection(projBD09);
      ol.proj.addCoordinateTransforms("EPSG:4326", "BD09",
        function (coordinate) {
          var result = gcoord.transform(
            coordinate, // 经纬度坐标
            gcoord.WGS84, // 当前坐标系
            gcoord.BD09MC // 目标坐标系
          )
          return result;
        },
        function (coordinate) {
          var result = gcoord.transform(
            coordinate, // 经纬度坐标
            gcoord.BD09MC, // 当前坐标系
            gcoord.WGS84 // 目标坐标系
          )
          return result;
        })

        ol.proj.addCoordinateTransforms("EPSG:3857", "BD09",
        function (coordinate) {
          var result = gcoord.transform(
            coordinate, // 经纬度坐标
            gcoord.EPSG3857, // 当前坐标系
            gcoord.BD09MC // 目标坐标系
          )
          return result;
        },
        function (coordinate) {
          var result = gcoord.transform(
            coordinate, // 经纬度坐标
            gcoord.BD09MC, // 当前坐标系
            gcoord.EPSG3857 // 目标坐标系
          )
          return result;
        })

      // 增加高德地图坐标系
      // let projGCJ02 = new ol.proj.Projection({
      //   code: 'GCJ-02',
      //   extent: [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244],
      //   units: 'm',
      //   axisOrientation: 'neu',
      //   global: false
      // });

      // ol.proj.addProjection(projGCJ02);
      // ol.proj.addCoordinateTransforms("EPSG:3857", 'GCJ-02',
      //   function (coordinate) {
      //     var result = gcoord.transform(
      //       coordinate, // 经纬度坐标
      //       gcoord.EPSG3857, // 当前坐标系
      //       gcoord.GCJ02 // 目标坐标系
      //     )
      //     return result;
      //   },
      //   function (coordinate) {
      //     var result = gcoord.transform(
      //       coordinate, // 经纬度坐标
      //       gcoord.GCJ02, // 当前坐标系
      //       gcoord.EPSG3857 // 目标坐标系
      //     )
      //     return result;
      //   })

      //   ol.proj.addCoordinateTransforms("EPSG:4326", 'GCJ-02',
      //   function (coordinate) {
      //     var result = gcoord.transform(
      //       coordinate, // 经纬度坐标
      //       gcoord.WGS84, // 当前坐标系
      //       gcoord.GCJ02 // 目标坐标系
      //     )
      //     return result;
      //   },
      //   function (coordinate) {
      //     var result = gcoord.transform(
      //       coordinate, // 经纬度坐标
      //       gcoord.GCJ02, // 当前坐标系
      //       gcoord.WGS84 // 目标坐标系
      //     )
      //     return result;
      //   })
    },
    createMapLayer(){
      this.remoteLayer = new ol.layer.Tile({
        source: this.constructSource(),
        name: '遥感影像图',
        id:'remote',
        type: 'remote',
        visible: false,
      })

      this.baiduMapLayer = new ol.layer.Tile({
        source: this.createBaiduMapSource('BD09'),
        crossOrigin: 'anonymous',
        id: 'baiduditu',
        visible: true,
        isGroup: true,
        name: '百度地图',
        canDark:true
      })

      this.baiduWxMapLayer = new ol.layer.Tile({
        source: this.createBaiduMapSource('BD09WX'),
        crossOrigin: 'anonymous',
        id: 'tiandituweixing',
        visible: false,
        isGroup: true,
        name: '百度地图卫星图',
      })

      this.baiduWxTextMapLayer = new ol.layer.Tile({
        source: this.createBaiduMapSource('BD09WXTEXT'),
        crossOrigin: 'anonymous',
        id: 'tiandituweixingtext',
        visible: false,
        isGroup: true,
        name: '百度地图卫星图标注',
      })

      this.tiandiMapLayer = new ol.layer.Tile({
        source: new ol.source.XYZ({
          url: 'https://t{0-7}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=dfb8269733afd739918d830a53036206',
          wrapX: false,
          // tileLoadFunction: this.darkfunction
          // cacheSize:0
        }),
        crossOrigin: 'anonymous',
        projection: 'EPSG:4326',
        id: 'tianditu',
        visible: false,
        isGroup: true,
        name: '天地图',
        canDark:true

      })

      this.tiandiTextLayer = new ol.layer.Tile({
        source: new ol.source.XYZ({
          url: 'https://t{0-7}.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=dfb8269733afd739918d830a53036206',
          wrapX: false,
        }),
        crossOrigin: 'anonymous',
        projection: 'EPSG:4326',
        id: 'tianditu_text',
        visible: false,
        isGroup: true,
        name: '天地图文字标注',
      })

      this.tiandiWxMapLayer = new ol.layer.Tile({
        source: new ol.source.XYZ({
          url: 'https://t{0-7}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=dfb8269733afd739918d830a53036206',
          wrapX: false,
        }),
        crossOrigin: 'anonymous',
        projection: 'EPSG:4326',
        id: 'tianditu',
        visible: false,
        isGroup: true,
        name: '天地图',
      })

      this.tiandiWxTextLayer = new ol.layer.Tile({
        source: new ol.source.XYZ({
          url: 'https://t{0-7}.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=dfb8269733afd739918d830a53036206',
          wrapX: false,
        }),
        crossOrigin: 'anonymous',
        projection: 'EPSG:4326',
        id: 'tianditu_text',
        visible: false,
        isGroup: true,
        name: '天地图文字标注',
      })

      this.gaodeMapLayer = new ol.layer.Tile({
        source: new ol.source.XYZ({
          url:'http://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}',
          projection:gcj02Mecator,
          wrapX: false,
        }),
          crossOrigin: 'anonymous',
        id: 'gaode',
        visible: false,
        isGroup: true,
        name: '高德地图',
        canDark:true
      })
      this.gaodeWxMapLayer = new ol.layer.Tile({
        source: new ol.source.XYZ({
          url: 'https://webst0{1-4}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
          projection:gcj02Mecator,
          wrapX: false,
        }),
        crossOrigin: 'anonymous',
        id: 'gaodewx',
        visible: false,
        isGroup: true,
        name: '高德卫星地图',
      })
      this.gaodeWxTextMapLayer = new ol.layer.Tile({
        source: new ol.source.XYZ({
          url:'http://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=8&x={x}&y={y}&z={z}',
          projection:gcj02Mecator,
          wrapX: false,
        }),
        crossOrigin: 'anonymous',
        id: 'gaodewx',
        visible: false,
        isGroup: true,
        name: '高德卫星地图',
      })
      this.mapLayers.push(this.baiduMapLayer,this.baiduWxMapLayer,this.tiandiMapLayer,this.tiandiWxMapLayer,this.gaodeMapLayer,this.gaodeWxMapLayer,this.remoteLayer,this.baiduWxTextMapLayer,this.tiandiTextLayer,this.tiandiWxTextLayer,this.gaodeWxTextMapLayer)
    },
    createBaiduMapSource(CRS){
      // 自定义分辨率和瓦片坐标系
      const resolutions = [];
      // 最大提供到18
      const maxZoom = 18;

      // 计算百度使用的分辨率
      for (var i = 0; i <= maxZoom; i++) {
        resolutions[i] = Math.pow(2, maxZoom - i);
      }

      // TileGrid的主要用途是定义地图瓦片的布局和加载方式
      const tilegrid = new ol.tilegrid.TileGrid({
        origin: [0, 0],    // 设置原点坐标
        resolutions: resolutions    // 设置分辨率
      });

      // 百度地图数据源
      const baiduSource = new ol.source.TileImage({
        projection: 'BD09',
        tileGrid: tilegrid,
        //wrapX: true,//允许左右无限移动
        // 参数tileCoord为瓦片坐标;pixelRatio为像素比;proj为投影
        tileUrlFunction: function (tileCoord, pixelRatio, proj) {
          if (!tileCoord) {
            return "";
          }
          var z = tileCoord[0];
          var x = tileCoord[1];
          // 注意，在openlayer3中由于载地图的方式是右上递增
          // let y: number | string = tileCoord[2];
          // 而openlayer6中是右下递增，所以y的值需要注意
          // 6版本需要取负值,同时注意要减一，否则缩放有问题
          // let y: number | string = -tileCoord[2] - 1;
          var y = -tileCoord[2] - 1;// ol6需要此处减1，否则缩放又偏移

          // 百度瓦片服务url将负数使用M前缀来标识
          if (x < 0) {
            x = 'M' + (-x);
          }
          if (y < 0) {
            y = 'M' + (-y);
          }

          // online3的3是用来分流的，还可以是其他路径
          // udt应该表示的是地图发布日期。p表示地图上的信息，scaler表示缩放
          // 0表示不显示地名等标注信息，只单纯的地图地图，1表示显示地名信息
          // return `http://online3.map.bdimg.com/onlinelabel/?qt=tile&x=${x}&y=${y}&z=${z}&styles=pl&udt=20220426&scaler=2&p=1`;//20190426

          if (CRS === 'BD09') {
            return "https://maponline" + Math.floor(Math.random() * 3) + ".bdimg.com/tile/?qt=vtile&x=" + x + "&y=" + y + "&z=" + z + "&styles=pl&udt=20241205&scaler=1&p=1";// 街道图
          }else if(CRS === 'BD09WX'){
            return "https://maponline" + Math.floor(Math.random() * 3) + ".bdimg.com/starpic/?qt=satepc&u=x=" + x + ";y=" + y + ";z=" + z + ";v=009;type=sate&fm=46&app=webearth2&v=009&udt=20241205";// 街道图
            // return "http://shangetu" + Math.floor(Math.random() * 10) + ".map.bdimg.com/it/u=x=" + x + ";y=" + y + ";z=" + z + ";v=009;type=sate&fm=46&udt=20241205"; //卫星图
          }else if(CRS === 'BD09WXTEXT'){
            return "http://online1.map.bdimg.com/tile/?qt=tile&x="+x+"&y="+y+"&z="+z+"&styles=sl&v=020"
          }
        },
        // tileLoadFunction: this.mapColorFunction
      });
      return baiduSource
    },
    createMap(){
      this.map = new ol.Map({
          target: 'map',
          layers: this.mapLayers,
          view: this.bd09view,
          controls: ol.control.defaults({
            zoom: true,//不显示放大放小按钮
            rotate: true,// 不显示指北针控件
            attribution: false//不显示右下角的地图信息控件
          }).extend([
            // 比例尺
            new ol.control.ScaleLine({
              //设置比例尺单位，degrees、imperial、us、nautical、metric（度量单位）
              units: "metric"
            })
          ])
        })
    },
    mapLayersVisible(){
      this.mapLayers.forEach(x=>{
        if(x.getProperties().id != 'remote'){
          x.setVisible(false);
        }
      })
    },
    createMapView(){
      this.bd09view = new ol.View({
        projection: 'BD09',
        center: ol.proj.transform([120.322437, 36.066585], 'EPSG:4326', 'BD09'),
        zoom: 13,
        minZoom: 0,// 最小缩放级别
        maxZoom: 18, //最大缩放级别
        constrainResolution: true,// 因为存在非整数的缩放级别，所以设置该参数为true来让每次缩放结束后自动缩放到距离最近的一个整数级别，这个必须要设置，当缩放在非整数级别时地图会糊
        enableRotation: false,// 禁止地图旋转
      })
      this.wgs84view = new ol.View({
        projection: 'EPSG:4326',
        center: [120.322437, 36.066585],
        zoom: 13,
        minZoom: 0,// 最小缩放级别
        maxZoom: 18, //最大缩放级别
        constrainResolution: true,// 因为存在非整数的缩放级别，所以设置该参数为true来让每次缩放结束后自动缩放到距离最近的一个整数级别，这个必须要设置，当缩放在非整数级别时地图会糊
        enableRotation: false,// 禁止地图旋转
      })
      this.gcj02view = new ol.View({
        projection: 'GCJ02',
        center: ol.proj.transform([121.322437, 36.066585], 'EPSG:4326', gcj02Mecator),
        zoom: 13,
        minZoom: 0,// 最小缩放级别
        maxZoom: 18, //最大缩放级别
        constrainResolution: true,// 因为存在非整数的缩放级别，所以设置该参数为true来让每次缩放结束后自动缩放到距离最近的一个整数级别，这个必须要设置，当缩放在非整数级别时地图会糊
        enableRotation: false,// 禁止地图旋转
      })
    },
    transformViewCRC(coor){
     return ol.proj.transform(coor,'EPSG:4326',this.map.getView().getProjection())
    }
  },
}
</script>

<style scoped lang="less">
#map {
  clear: both;
  position: relative;
  width: 100%;
  height: 100%;
  ::v-deep .ol-logo-only {
    display: none;
  }
  ::v-deep .ol-zoom {
    top: 1.2em;
    left: 0.8em;
  }
}
#container {
  clear: both;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
